import Vue from "vue";

import qs from "qs";

import { appConfig } from "./core/app-config";

import { formatNumber, formatBigNumber, formatPercent } from "./core/number";
import { formatConsensus } from "./core/filters/consensus";
import { ColorPicker } from "./core/color-picker";
import { Sectors } from "./core/sectors";
import { DateHelper } from "./core/date";

import { $axios } from "./core/axios";
import store from "./store";

import "./styles/reset.css";
import "./styles/main.scss";

Vue.config.productionTip = false;

Vue.filter("number", formatNumber);
Vue.filter("bigNumber", formatBigNumber);
Vue.filter("percent", formatPercent);

Vue.filter("consensus", formatConsensus);
Vue.filter("sectors", Sectors.getLabel);

Vue.filter("date", DateHelper.getHumanFriendlyDate);
Vue.filter("dateTime", DateHelper.formatDateTime);

Vue.prototype.$appConfig = appConfig;
Vue.prototype.$axios = $axios;
Vue.prototype.$pickColor = ColorPicker;


$axios.defaults.paramsSerializer = function (params) {
  return qs.stringify(params);
};

export function createApp({ rootComponent, instanceOptions }) {
  $axios.setToken(appConfig.token);

  new Vue({
    store,
    render: h => h(rootComponent),
    ...instanceOptions
  }).$mount("#app");
}
