/** Склонение существительных
 * Использование:
 *   declOfNum(10, ["яблоко", "яблока", "яблок"])
 *                     1         2         5
 */
export function declOfNum(n, titles) {
  n = +n;
  return titles[
    n % 10 === 1 && n % 100 !== 11 ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2
  ];
}

/**
 * Дебаунс функции(func) с интервалом(wait).
 */
export function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}
