import axios from "axios";

export const $axios = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 30e3
});

for (let method of ["request", "delete", "get", "head", "options", "post", "put", "patch"]) {
  $axios["$" + method] = function () {
    return this[method].apply(this, arguments).then(res => res && res.data);
  };
}

$axios.setToken = token => {
  $axios.defaults.headers.common.Authorization = "Bearer " + token;
};

$axios.interceptors.response.use(undefined, handleError);

function handleError(error) {
  if (error?.response?.status === 401) {
    location.href = "/";
  } else {
    throw error;
  }
}
