import { $axios } from "~/common/core/axios";

export const analyzes = {
  strict: true,
  namespaced: true,
  state: {
    stocks: {}
  },
  actions: {
    async fetchStocks({ state, dispatch, commit }, { tickers = [] } = {}) {
      tickers = tickers.filter(ticker => !state.stocks[ticker]);

      if (!tickers.length) return;

      const { data: analyzes } = await $axios.$get("analysis", { params: { tickers } });

      await dispatch("stocks/fetchOverviews", { tickers }, { root: true });

      commit("setStocks", analyzes);
    }
  },
  mutations: {
    setStocks(state, analyzes) {
      analyzes = analyzes.reduce((acc, stock) => {
        return { ...acc, [stock.ticker]: { ...stock } };
      }, {});

      state.stocks = { ...state.stocks, ...analyzes };
    }
  }
};
