export class Numbers {
  static parseNumber(data) {
    if (typeof data === "number") return data;

    if (typeof data === "string") {
      return Number.parseFloat(data.replace(/\s/g, "").replace(",", "."));
    }

    return Number.parseFloat(data);
  }

  static getCurrencyFormative(currencyName) {
    switch (currencyName) {
      case "USD": {
        return { startAt: "", endAt: " $" };
      }
      case "RUB": {
        return { startAt: "", endAt: " ₽" };
      }
      default: {
        return { startAt: "", endAt: " у.е." };
      }
    }
  }
}

export const formatNumber = (
  data,
  { showPlus = false, currency = null, fractionDigits = 2, abs = false, tailingZero = true } = {}
) => {
  const number = Numbers.parseNumber(data);

  if (Number.isNaN(number)) {
    return "Нет данных";
  }

  let startAt = "";
  let endAt = "";

  if (currency) {
    ({ startAt, endAt } = Numbers.getCurrencyFormative(currency));
  }

  // Схлопывать "0,00" -> 0
  if (!tailingZero && Number.isInteger(number)) {
    fractionDigits = 0;
  }

  let str = Math.abs(number).toLocaleString("ru-RU", {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
    currency: "RUB"
  });

  if (number > 0 || abs) {
    let res = `${startAt}${str}${endAt}`;

    return showPlus ? "+" + res : res;
  } else if (number < 0) {
    return `-${startAt}${str}${endAt}`;
  } else {
    let res = `${startAt}0`;
    const fraction = "0".repeat(fractionDigits);

    if (fraction) {
      res = `${res},${fraction}`;
    }

    return res + endAt;
  }
};

export const formatBigNumber = (data, options = {}) => {
  let number = Numbers.parseNumber(data);

  let base = 0,
    isNegative = number < 0,
    notationValue = "",
    notations = [" тыс.", " млн.", " млрд.", " трлн."];

  if (!Number.isFinite(number)) return "Нет данных";

  number = Math.abs(number);

  if (number >= 1000) {
    number /= 1000;
    while (Math.round(number) >= 1000) {
      number /= 1000;
      base++;
    }
    if (base >= notations.length) {
      return "Нет данных";
    } else {
      notationValue = notations[base];
    }
  }

  let numberAsString = formatNumber(Math.round(number * 1000) / 1000, options);

  return (isNegative ? "-" : "") + numberAsString + notationValue;
};

export const formatPercent = (data, { showPlus = true } = {}) => {
  const number = Numbers.parseNumber(data);

  if (Number.isNaN(number)) {
    return "Нет данных";
  }

  let str = Math.abs(number).toLocaleString("ru-RU", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    currency: "RUB"
  });

  if (number > 0) {
    let res = number < 0.01 ? "<0,01%" : `${str}%`;

    return showPlus ? "+" + res : res;
  } else if (number < 0) {
    if (number > -0.01) return "-<0,01%";

    return `-${str}%`;
  } else {
    return "0,00%";
  }
};
