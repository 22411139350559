import qs from "qs";

class AppConfig {
  constructor() {
    this.params = qs.parse(location.search, { ignoreQueryPrefix: true });

    this.token = this.params.token;
    this.spbeOnly = +this.toBoolean(this.params.spbe_only);
    this.capitalization = this.params.capitalization;
    this.origin = this.getOriginFromParam(this.params.origin); // "all" | "russian" | "american" | undefined
  }

  toBoolean(value) {
    return ["1"].includes(value);
  }

  getOriginFromParam(origin) {
    if (!origin || origin === "russian") {
      return "russian";
    }

    if (origin === "american") {
      return "american";
    }

    // "all" and others reset origin
    return undefined;
  }
}

export const appConfig = new AppConfig();
