import Vue from "vue";
import Vuex from "vuex";

import { stocks } from "./stocks";
import { analyzes } from "./analyzes";
import { potential } from "./potential";
import { consensus } from "./consensus";
import { recommendations } from "./recommendations";

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  modules: {
    stocks,
    analyzes,
    potential,
    consensus,
    recommendations
  }
});
