import { $axios } from "~/common/core/axios";

export const recommendations = {
  strict: true,
  namespaced: true,
  state: {
    stocks: {}
  },
  actions: {
    async fetch({ commit }, ticker) {
      const recommendations = await $axios.$get("analysts/" + ticker);

      commit("setStocks", { ticker, recommendations });
    }
  },
  mutations: {
    setStocks(state, { ticker, recommendations }) {
      state.stocks = { ...state.stocks, [ticker]: recommendations };
    }
  }
};
