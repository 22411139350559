import { $axios } from "~/common/core/axios";

export const stocks = {
  strict: true,
  namespaced: true,
  state: {
    overviews: {}
  },
  actions: {
    async fetchOverviews({ state, commit }, { strict = false, tickers = [] } = {}) {
      tickers = tickers.filter(ticker => !state.overviews[ticker]);

      if (!tickers.length) return;

      const { data: overviews } = await $axios.$get("stocks", {
        params: { tickers, limit: tickers.length }
      });

      if (strict && overviews.length < tickers.length) {
        throw { response: { status: 404 } };
      }

      commit("setOverviews", overviews);
    },

    /**
     * id must be ticker or isin
     */
    async fetchOverviewOnce({ commit }, id) {
      const stock = await $axios.$get("stocks/" + id);

      commit("setOverviews", [stock]);

      return stock;
    }
  },
  mutations: {
    setOverviews(state, overviews) {
      overviews = overviews.reduce((acc, stock) => {
        return { ...acc, [stock.ticker]: { ...stock } };
      }, {});

      state.overviews = { ...state.overviews, ...overviews };
    }
  }
};
