import { $axios } from "~/common/core/axios";
import { appConfig } from "../core/app-config";

const defaultPotentialFilter = [{ max: 5 }];

export const potential = {
  strict: true,
  namespaced: true,
  state: {
    stocks: {
      count: 0,
      limit: 10,
      offset: 0,
      data: []
    }
  },
  actions: {
    async fetchStocks(
      { dispatch, commit },
      { limit = 10, offset = 0, forAlfa = undefined, potential = defaultPotentialFilter } = {}
    ) {
      try {
        const stocks = await $axios.$get("stocks/top/potential", {
          params: {
            limit,
            offset,
            forAlfa,
            potential,
            spbeOnly: appConfig.spbeOnly,
            capitalization: appConfig.capitalization,
            origin: appConfig.origin
          }
        });

        const tickers = stocks.data.map(stock => stock.ticker);

        await dispatch("stocks/fetchOverviews", { tickers }, { root: true });

        commit("setStocks", stocks);
      } catch {
        // do nothing
      }
    },

    async fetchMore({ state, dispatch, commit }, { forAlfa = undefined, potential = defaultPotentialFilter } = {}) {
      try {
        const { limit } = state.stocks;
        const offset = state.stocks.offset + limit;

        if (offset >= state.stocks.count) return;

        const stocks = await $axios.$get("stocks/top/potential", {
          params: {
            limit,
            offset,
            forAlfa,
            potential,
            spbeOnly: appConfig.spbeOnly,
            capitalization: appConfig.capitalization,
            origin: appConfig.origin
          }
        });

        const tickers = stocks.data.map(stock => stock.ticker);

        await dispatch("stocks/fetchOverviews", { tickers }, { root: true });

        const nextStocks = {
          ...stocks,
          data: [...state.stocks.data, ...stocks.data]
        };

        commit("setStocks", nextStocks);
      } catch {
        // do nothing
      }
    }
  },
  mutations: {
    setStocks(state, stocks) {
      state.stocks = stocks;
    }
  }
};
