import { Numbers } from "./number";

export const colors = ["green", "green-light", "grey", "red-light", "red"];

const ColorTypes = {
  POSITIVE: "green",
  NEUTRAL: "grey",
  NEGATIVE: "red"
};

export class ColorPicker {
  static consensusColorMap = {
    StrongBuy: ColorTypes.POSITIVE,
    ModerateBuy: ColorTypes.POSITIVE,
    Buy: ColorTypes.POSITIVE,
    buy: ColorTypes.POSITIVE,

    StrongSell: ColorTypes.NEGATIVE,
    ModerateSell: ColorTypes.NEGATIVE,
    Sell: ColorTypes.NEGATIVE,
    sell: ColorTypes.NEGATIVE
  };

  static ofNumber(prefix, number) {
    if (prefix) prefix += "-";

    number = Numbers.parseNumber(number);

    let color = ColorTypes.NEUTRAL;

    if (number > 0) {
      color = ColorTypes.POSITIVE;
    } else if (number < 0) {
      color = ColorTypes.NEGATIVE;
    }

    return prefix + color;
  }

  static ofConsensus(prefix, value) {
    if (prefix) prefix += "-";

    const color = ColorPicker.consensusColorMap[value] ?? ColorTypes.NEUTRAL;

    return prefix + color;
  }
}
