export class Sectors {
  static sectorMap = {
    Healthcare: "Здравоохранение",
    Technology: "Технологии",
    Materials: "Сырьевая промышленность",
    "Consumer Goods": "Потребительские товары",
    Utilities: "Коммунальный сектор",
    Services: "Сфера услуг",
    Financial: "Финансовый сектор",
    "Industrial Goods": "Промышленность"
  };

  static getAll() {
    return this.sectorMap;
  }

  static getLabel(sector) {
    return Sectors.sectorMap[sector] ?? "Нет данных";
  }
}
